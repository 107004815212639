<template>
  <el-main>

    <el-row>
      <el-col :span="16">
        <el-form :inline="true" :model="form">
          <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="全部" clearable>
              <el-option v-for="item in options.status" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平台">
            <el-select v-model="form.platform_id" placeholder="全部" clearable>
              <el-option v-for="item in options.platform" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门" v-if="showCreator">
            <el-select v-model="form.department_id" placeholder="全部" clearable>
              <el-option v-for="item in options.department" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col span="8">
        <el-carousel trigger="click" height="50px" arrow="never" indicator-position="none" direction="vertical"
                     interval="10000">
          <el-carousel-item v-for="i in 5" :key="i">
            <div>已有{{ vipCount }}个部门成为<span class="vip">VIP</span>，获得每日独享30个关键词的额度</div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-dropdown @command="addTask">
          <el-button type="primary">
            新建任务<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="keyword">新建关键词任务</el-dropdown-item>
            <el-dropdown-item command="comment">新建评论任务</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="status_cn"
          label="状态"
      >
      </el-table-column>
      <el-table-column
          prop="platform_name"
          label="平台"
      >
      </el-table-column>
      <el-table-column
          prop="type_cn"
          label="任务类型">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注">
      </el-table-column>
      <el-table-column
          prop="estimated_queue_time_cn"
          label="预计等待时间">
      </el-table-column>
      <el-table-column
          prop="creator_name"
          label="创建人" v-if="showCreator">
      </el-table-column>
      <el-table-column
          prop="insert_time"
          label="创建时间">
      </el-table-column>
      <el-table-column
          prop="finish_time"
          label="完成时间">
      </el-table-column>
      <el-table-column
          label="操作" align="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="viewDetail(scope)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @current-change="turnPage"
        :current-page.sync="currentPage"
        :page-size="size"
        layout="total, prev, pager, next"
        :total="dataCount">
    </el-pagination>
  </el-main>
</template>

<script>
export default {
  name: "TaskList",
  created() {
    let role = localStorage.getItem("role");
    if (role == 1) {
      this.showCreator = false;
    } else if (role == 99) {
      this.showCreator = true;
    }
    let me = this;
    Promise.all([me.axios.get('/api/platform/list')
      , me.axios.get('/api/department/list')
      , me.axios.get('/api/user/list')])
        .then(([platforms, departments, users]) => {
          platforms.forEach(platform => {
            me.options.platform.push(platform);
            me.map.platform['id_' + platform.id] = platform.name;
          })
          departments.forEach(department => {
            me.options.department.push(department);
          })
          users.forEach(user => {
            me.map.user['id_' + user.id] = user.name;
          })
          me.search();
          me.threadId = setInterval(me.search, 5000);
        })
    me.axios.get('/api/user/vipInfos').then(res => {
      me.vipCount = res;
    });
  },
  beforeDestroy() {
    clearInterval(this.threadId);
  },
  data: function () {
    return {
      map: {
        user: {},
        platform: {},
        status: {
          'id_1': '排队中',
          'id_2': '执行中',
          'id_100': '已完成'
        },
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        }
      },
      options: {
        status: [
          {
            id: 1,
            name: '排队中',
          },
          {
            id: 2,
            name: '执行中',
          },
          {
            id: 100,
            name: '已完成',
          }
        ],
        platform: [],
        department: []
      },
      showCreator: false,
      form: {
        status: null,
        platform_id: null,
        remark: '',
        department_id: null
      },
      rawData: [],
      dataCount: 0,
      currentPage: 1,
      size: 10,
      threadId: null,
      vipCount: 1
    }
  },
  computed: {
    tableData: function () {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(row => {
        row['type_cn'] = me.map.type['id_' + row['type']];
        row['estimated_queue_time_cn'] = me.formatTime(row['estimated_queue_time']);
        if ((row['status'] == 1 || row['status'] == 2) && row['estimated_queue_time'] == null) {
          row['estimated_queue_time_cn'] = "计算中...";
        }
        row['platform_name'] = me.map.platform['id_' + row['platform_id']];
        row['status_cn'] = me.map.status['id_' + row['status']];
        row['creator_name'] = me.map.user['id_' + row['creator_id']];
        return row;
      })

    }
  },
  methods: {
    viewDetail: function (scope) {
      this.$router.push('/home/task/detail?id=' + scope.row.id);
    },
    search: function () {
      let me = this;
      let params = {};
      Object.assign(params, this.form, {page: me.currentPage, size: me.size})
      this.axios.post('/api/job/list', params).then(res => {
        me.rawData = res.list;
        me.dataCount = res.totalRow;
      })
    },
    turnPage: function (page) {
      this.currentPage = page;
      this.search();
    },
    addTask: function (command) {
      this.$router.push('/home/task/' + command);
    }
  }
}
</script>

<style scoped>
.vip {
  color: gold;
  font-weight: bold;
}
</style>